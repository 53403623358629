<template>
  <div>
    <validation-observer v-slot="{ invalid }">
      <v-form id="form-register" @submit.prevent="checkRegister">
        <validation-provider
          v-slot="{ errors }"
          name="Name"
          rules="required"
        >
          <v-text-field
            label="Nama"
            placeholder="Nama"
            v-model="name"
            :error-messages="errors"
            clearable
            type="text"
            outlined
            dense
          ></v-text-field>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="Email"
          rules="required|email"
        >
          <v-text-field
            label="E-mail"
            placeholder="E-mail"
            v-model="email"
            :error-messages="errors"
            clearable
            type="text"
            outlined
            dense
          ></v-text-field>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="WA Phone"
          rules="required|phoneNumber"
        >
          <v-text-field
            label="Nomor WA"
            placeholder="Nomor WA"
            prefix="+62"
            v-model="phoneNumber"
            :error-messages="errors"
            clearable
            type="number"
            outlined
            dense
          ></v-text-field>
        </validation-provider>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            :min-width="160"
            type="submit"
            form="form-register"
            large
            :disabled="isDisabled || invalid"
          >
            DAFTAR
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfo" :info="info" :action="goToLogin"/>
  </div>
</template>

<script>
  // Libs 
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { required, email } from 'vee-validate/dist/rules'
  import { get } from 'dot-prop'

  // Utils
  import loadscreen from '@/utils/common/loadScreen'
  import request from '@/utils/request'

  // Constants
  import {
    API_REGISTER
  } from '@/constants/apis'
  import { LOGIN } from '@/constants/pages'

  // Components
  import DialogInfo from '@/components/common/DialogInfo.vue'

  export default {
    name: 'FormRegister',
    components: {
      ValidationObserver,
      ValidationProvider,
      DialogInfo
    },
    data () {
      return {
        name: '',
        email: '',
        phoneNumber: '',
        isError: false,
        isShowAllert: false,
        show: false
      }
    },
    computed: {
      isDisabled () {
        return !this.name || !this.email || !this.phoneNumber || this.isError
      },
      info () {
        return {
          title: 'register.dialog.title',
          desc: 'register.dialog.desc',
          btn: 'register.dialog.btn'
        }
      }
    },
    mounted () {
      extend('required', required)
      extend('email', email)
      extend('phoneNumber', {
        validate: (value) => {
          const MOBILEREG = /^(^8)(\d{3,4}-?){2}\d{2,3}$/g
          if (MOBILEREG.test(value)) {
            return true
          }
          return 'WA Phone Number is not valid'
        }
      })
    },
    methods: {
      async checkRegister () {
        this.$emit('handleAlert', false, '')
        loadscreen.show()
        const param = {
          name: this.name,
          email: this.email,
          phone: `+62${this.phoneNumber}`,
          role: 'mahasiswa'
        }
        const res = await request(API_REGISTER, param)
        if (res.success) {
          this.$store.dispatch('web/setIsLoginInfo', true)
          if (this.$refs.dialogInfo) {
            this.$refs.dialogInfo.show()
          } else {
            this.goToLogin()
          }
        } else {
          const message = get(res, 'resultInfo.resultMsg', '')
          this.$emit('handleAlert', true, message)
        }
        loadscreen.hide()
      },
      goToLogin () {
        this.$router.push({
          name: LOGIN
        }).catch(() => {})
      }
    },
  }
</script>

<style lang="scss" scoped>
.form-login__link {
  font-weight: bold;
  color: #1E355E;
}
</style>