<template>
  <div>
    <validation-observer ref="formReference" v-slot="{ invalid }">
      <v-form id="form-forgot-password" @submit.prevent="checkForgotPassword">
        <validation-provider
          v-slot="{ errors }"
          name="Email"
          rules="required|email"
        >
          <v-text-field
            label="E-mail"
            placeholder="E-mail"
            v-model="email"
            :error-messages="errors"
            clearable
            type="text"
            outlined
            dense
          ></v-text-field>
        </validation-provider>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            :min-width="160"
            type="submit"
            form="form-forgot-password"
            large
            :disabled="isDisabled || invalid"
          >
            KIRIM
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfo" :info="info"/>
  </div>
</template>

<script>
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { required, email } from 'vee-validate/dist/rules'
    import { get } from 'dot-prop'

  // Utils
  import loadscreen from '@/utils/common/loadScreen'
  import request from '@/utils/request'

  // Constants
  import {
    API_FORGOT_PASSWORD
  } from '@/constants/apis'

  // Components
  import DialogInfo from '@/components/common/DialogInfo.vue'

  export default {
    name: 'FormForgotPassword',
    components: {
      ValidationObserver,
      ValidationProvider,
      DialogInfo
    },
    data () {
      return {
        email: '',
        isError: false,
        isShowAllert: false,
      }
    },
    computed: {
      isDisabled () {
        return !this.email || this.isError
      },
      info () {
        return {
          title: 'forgot.password.dialog.title',
          desc: 'forgot.password.dialog.desc',
          btn: 'forgot.password.dialog.btn'
        }
      }
    },
    mounted () {
      extend('required', required)
      extend('email', email)
    },
    methods: {
      async checkForgotPassword () {
        this.$emit('handleAlert', false, '')
        loadscreen.show()
        const params = {
          email: this.email,
        }
        const res = await request(API_FORGOT_PASSWORD, params)
        if (res.success) {
          if (this.$refs.dialogInfo) {
            this.$refs.dialogInfo.show()
          }
          if (this.$refs.formReference){
            this.email = ''
            this.$nextTick(() => {
              this.$refs.formReference.reset()
            })
          }
        } else {
          const message = get(res, 'resultInfo.resultMsg', '')
          this.$emit('handleAlert', true, message)
        }
        loadscreen.hide()
      },
    }
  }
</script>

<style lang="scss" scoped>
.form-login__link {
  font-weight: bold;
  color: #1E355E;
}
</style>