<template>
  <div class="register-web">
    <v-container>
      <div class="main-container">  
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="12"
            md="8"
            lg="9"
          >
            <v-card
              rounded="lg"
              class="pa-9"
              :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
            >
              <h2>{{ 'register.form.title' | text }}</h2>
              <p class="mb-4">{{ 'register.form.desc' | text }}</p>
              <v-alert
                dense
                border="left"
                type="info"
              >
                Admisi STT Amanat Agung akan mengirimkan <strong>PASSWORD</strong> Anda melalui <strong>E-mail</strong>, jadi pastikan E-mail yang Anda daftarkan aktif.
              </v-alert>
              <v-alert
                dismissible
                type="error"
                v-model="isShowAlert"
              >{{ alertMsg }}</v-alert>
              <form-register @handleAlert="handleAlert" />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
          >
            <v-card
              rounded="lg"
              class="pa-9 register-web__section_add"
              :class="{'py-9 mx-0 mt-4 px-4': $vuetify.breakpoint.smAndDown}"
            >
              <p>{{ 'register.section.right.title' | text }}</p>
              <v-btn color="primary" class="btn-custom_sm my-4" large @click="goToLogin">
                {{ 'register.section.right.btn' | text }}
              </v-btn>
              <p>{{ 'register.section.right.desc' | text }}</p>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import {
  LOGIN
} from '@/constants/pages'
import FormRegister from '@/apps/web/components/forms/formRegister.vue'

  export default {
    name: 'RegisterWeb',
    components: {
      FormRegister,
    },
    data() {
      return {
        isShowAlert: false,
        alertMsg: ''
      }
    },
    head: {
      link: [
        { rel: 'canonical', href: 'https://admisi.sttaa.ac.id/register', id: 'canonical' },
      ]
    },
    methods: {
      goToLogin () {
        this.$router.push({
          name: LOGIN
        }).catch(() => {})
      },
      handleAlert (isShowAlert, msg) {
        this.isShowAlert = isShowAlert
        this.alertMsg = msg
      }
    }
  }
</script>

<style lang="scss" scoped>
.register-web {
  min-height: 500px;
  &__section_add {
    margin-left: 36px;
    text-align: center;
  }
  p {
    margin: 0;
  }
}
</style>